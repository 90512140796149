import { Box, Image, VStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTheme } from "../../context";

export function BannerCarousel({
    banners = [],
    isMobile = false,
    padding = "60px 0%"
}) {
    const { localModule, ecommerceModule, bookstoreModule } = useTheme();
    const [filteredBanners, setFilteredBanners] = useState([]);

    useEffect(() => {
        const newBanners = [];

        banners.forEach((b) => {
            if (b.type === "discount" && (localModule || ecommerceModule))
                newBanners.push(b);
            else if (b.type === "book" && bookstoreModule) newBanners.push(b);
        });

        setFilteredBanners(newBanners);
    }, [banners]);

    if (filteredBanners.length === 0) return <></>;

    return (
        <VStack width={"100%"} align="flex-start">
            <Carousel indicators={false}>
                {filteredBanners.map((b, i) => (
                    <Carousel.Item key={i}>
                        <Box className="layered-box" borderRadius="5px">
                            <Link
                                to={
                                    b.discount
                                        ? `/detalhes/${b.discount.mode}/${b.discount.id}`
                                        : b.url ? b.url : "#"
                                }
                            >
                                <Image
                                    borderRadius="5px"
                                    objectFit="cover"
                                    height="auto"
                                    maxHeight={"400px"}
                                    width="100%"
                                    src={isMobile ? b.image_mobile : b.image}
                                />
                            </Link>
                        </Box>
                    </Carousel.Item>
                ))}
            </Carousel>
        </VStack>
    );
}
