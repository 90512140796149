import {
    Flex,
    HStack,
    TabPanel,
    TabPanels,
    Tabs,
    css,
    VStack,
    Center,
    useToast,
    Box
} from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import { WhatsappBall } from "../../components/atoms/WhatsappBall";
import { DiscountFilterBar } from "../../components/molecules/DiscountFilterBar";
import { useCheckMobile } from "../../utils";
import { useTheme } from "../../context/index";
import { CustomTab } from "../../components/molecules/CustomTabs";
import { SectionHeader } from "../../components/atoms/SectionHeading";
import { FilterTag } from "../../components/molecules/FilterTag";
import { useMutation, useQuery } from "react-query";
import { getCategories } from "../../api/category";
import { BannerCarousel } from "../../components/molecules/BannerCarousel";
import { IFrameBar } from "../../components/organisms/IFrameBar";
import { NewDiscountList } from "../../components/molecules/NewDiscountList";
import { useSettings } from "../../context/index";
import { useLocation } from "react-router-dom";
import { CustomTabList } from "../../components/organisms/CustomTabList";
import { TopMenu } from "../../components/organisms/TopMenu";
import { useGeolocation } from "../../context/geolocation";
import { CurrentLocation } from "../../components/molecules/CurrentLocation";
import { SearchBar } from "../../components/atoms/Searchbar";
import { getPromotions } from "../../api/discounts";

export const SearchPage = ({ openModal }) => {
    const [isFavorite, setIsFavorite] = useState(false);
    const [isCinema, setIsCinema] = useState(false);
    const router = useLocation();
    const geolocation = useGeolocation();
    const [search, setSearch] = useState("");
    const [type, setType] = useState({});
    const [category, setCategory] = useState(router.state);
    const [params, setParams] = useState({});
    const [location, setLocation] = useState(null);
    const { id, banners, name, defaultBackgroundColor } = useTheme();
    const { isApp, isIOS } = useSettings();
    const { data: categories = [] } = useQuery("category", () =>
        getCategories({ agreementId: id })
    );
    const exclusivePromotionMutation = useQuery(
        [
            "promotions-exclusive",
            {
                agreement: id,
                exclusive: true,
                ...geolocation.getLocationObject()
            }
        ],
        () =>
            getPromotions({
                agreement: id,
                exclusive: true,
                ...geolocation.getLocationObject()
            }),
        {
            enabled: !!id
        }
    );
    const localPromotionMutation = useQuery(
        [
            "promotions-exclusive",
            {
                agreement: id,
                mode: "local",
                ...geolocation.getLocationObject()
            }
        ],
        () =>
            getPromotions({
                mode: "local",
                agreement: id,
                ...geolocation.getLocationObject()
            }),
        {
            enabled: !!id && geolocation.allowedGeolocation
        }
    );

    const mobile = useCheckMobile();

    const orderedCategories = useMemo(
        () =>
            categories
                .map((c) => ({
                    ...c,
                    selected: (category || []).indexOf(c.id) !== -1
                }))
                .sort((a, b) => b.selected - a.selected)
                .map((c) => (
                    <FilterTag
                        key={c.id}
                        icon={`fas fa-${c.icon}`}
                        onClick={() => toggleCategory(c.id)}
                        isActive={c.selected}
                    >
                        {c.name}
                    </FilterTag>
                )),
        [categories, category]
    );

    useEffect(() => {
        setParams({
            type: type.id,
            category: isCinema ? ["cinema"] : category,
            name: search,
            ...location
        });
    }, [category, search, location, type, isCinema, setParams]);

    function toggleCategory(categoryId) {
        const newCategories = [...(category || [])];
        const index = newCategories.indexOf(categoryId);

        if (index === -1) {
            newCategories.push(categoryId);
        } else {
            newCategories.splice(index, 1);
        }

        if (newCategories.length > 0) setCategory(newCategories);
        else setCategory(null);
    }

    return (
        <Flex
            minHeight="calc(100vh - 60px)"
            maxHeight="fit-content"
            width="100%"
            background={defaultBackgroundColor}
            direction="column"
            paddingTop={{ base: "60px", lg: "0px" }}
        >
            <TopMenu />
            {isApp ? (
                <Box
                    paddingTop={{
                        base: "50px",
                        md: "100px"
                    }}
                    paddingLeft={{ base: "5%", md: "10%" }}
                >
                    <CurrentLocation />
                </Box>
            ) : (
                <></>
            )}

            <Flex
                backgroundColor={defaultBackgroundColor}
                paddingTop={isApp ? "15px" : "0px"}
                flex="1"
                id="stores-container"
                height="100%"
                alignItems="center"
                width="100%"
                overflow="unset"
                direction="column"
            >
                {!isApp ? (
                    <VStack
                        paddingBottom="50px"
                        paddingTop={{ base: "30px", md: "0px" }}
                        width="100%"
                    >
                        <Flex
                            width="100%"
                            display={{ base: "none", md: "block" }}
                        >
                            <BannerCarousel
                                padding="5% 10% 2% 10%"
                                banners={banners}
                            />
                        </Flex>
                        <Flex
                            width="100%"
                            display={{ base: "block", md: "none" }}
                        >
                            <BannerCarousel isMobile banners={banners} />
                        </Flex>
                    </VStack>
                ) : (
                    <></>
                )}

                <Flex height="100%" direction="column" width="100%">
                    {!isApp ? (
                        <Box
                            paddingLeft="11%"
                            paddingTop="10px"
                            paddingBottom="10px"
                        >
                            <CurrentLocation />
                        </Box>
                    ) : (
                        <></>
                    )}
                    <IFrameBar />

                    <Tabs isLazy borderColor="#E0E0E0" width="100%">
                        <VStack spacing={0} paddingTop="10px" id="search-bar">
                            <CustomTabList>
                                <CustomTab icon="fas fa-tag" fontWeight="bold">
                                    Todos
                                </CustomTab>
                                <CustomTab
                                    icon="fas fa-film"
                                    fontWeight="bold"
                                    subtitle=""
                                >
                                    Cinemas
                                </CustomTab>
                                <CustomTab
                                    icon="fas fa-store"
                                    fontWeight="bold"
                                    subtitle="compras pela internet"
                                >
                                    E-commerce
                                </CustomTab>
                                {localPromotionMutation?.data?.length > 0 ||
                                location?.state ? (
                                    <CustomTab
                                        icon="fas fa-map-marker-alt"
                                        fontWeight="bold"
                                        subtitle="perto de você"
                                    >
                                        Lojas Físicas
                                    </CustomTab>
                                ) : (
                                    <></>
                                )}
                                {exclusivePromotionMutation?.data?.length >
                                0 ? (
                                    <CustomTab
                                        icon="fas fa-star"
                                        fontSize="18px"
                                        fontWeight="bold"
                                        subtitle={`apenas do clube ${name}`}
                                    >
                                        Exclusivos
                                    </CustomTab>
                                ) : (
                                    <></>
                                )}
                                <CustomTab
                                    icon="fas fa-heart"
                                    fontWeight="bold"
                                    subtitle="do seu coração"
                                >
                                    Favoritos
                                </CustomTab>
                            </CustomTabList>
                            {!isApp && (
                                <DiscountFilterBar
                                    flex="1"
                                    mobile={mobile}
                                    hidePhysical={geolocation === false}
                                    onSearchChange={setSearch}
                                    onLocationChange={setLocation}
                                    geolocation={geolocation}
                                    search={search}
                                />
                            )}
                        </VStack>

                        {!isFavorite && !isCinema && (
                            <VStack
                                spacing={4}
                                alignItems="baseline"
                                width="100%"
                                paddingTop="10px"
                                margin="auto"
                                position="relative"
                            >
                                <SectionHeader
                                    paddingLeft={{ base: "4%", lg: "9.7%" }}
                                    title="Estou em busca de: "
                                ></SectionHeader>
                                <HStack
                                    paddingLeft={{ base: "4%", lg: "9.7%" }}
                                    overflowX="scroll"
                                    width="100%"
                                    css={css({
                                        scrollbarWidth: "none",
                                        "::-webkit-scrollbar": {
                                            display: "none"
                                        },
                                        WebkitOverflowScrolling: "touch",
                                        border: "0 none"
                                    })}
                                    spacing={3}
                                >
                                    {orderedCategories}
                                </HStack>
                                {isApp && (
                                    <SearchBar
                                        value={search}
                                        onChange={(t) => setSearch(t)}
                                    />
                                )}
                            </VStack>
                        )}
                        <TabPanels paddingTop="20px">
                            <TabPanel>
                                <NewDiscountList
                                    key="all"
                                    params={{
                                        ...params,
                                        ...geolocation.getLocationObject(),
                                        mode: geolocation.hasGeolocation()
                                            ? undefined
                                            : "online"
                                    }}
                                    name="all"
                                />
                            </TabPanel>
                            <TabPanel>
                                <NewDiscountList
                                    key="cinema"
                                    params={{
                                        category: "cinema",
                                        ...geolocation.getLocationObject()
                                    }}
                                    name="cinema"
                                />
                            </TabPanel>
                            <TabPanel>
                                <NewDiscountList
                                    params={{
                                        ...params,
                                        mode: "online"
                                    }}
                                    name="online"
                                />
                            </TabPanel>
                            {localPromotionMutation?.data?.length > 0 ? (
                                <TabPanel>
                                    <NewDiscountList
                                        params={{
                                            ...params,
                                            ...geolocation.getLocationObject(),
                                            mode: "local"
                                        }}
                                        name="local"
                                    />
                                </TabPanel>
                            ) : (
                                <></>
                            )}
                            {exclusivePromotionMutation?.data?.length > 0 ? (
                                <TabPanel>
                                    <NewDiscountList
                                        params={{
                                            ...params,
                                            ...geolocation.getLocationObject(),
                                            exclusive: true
                                        }}
                                        name="exclusive"
                                    />
                                </TabPanel>
                            ) : (
                                <TabPanel>
                                    <NewDiscountList
                                        params={{
                                            favorite: true,
                                            ...geolocation.getLocationObject()
                                        }}
                                        name="favorites"
                                    />
                                </TabPanel>
                            )}
                        </TabPanels>
                    </Tabs>

                    <WhatsappBall />
                </Flex>
            </Flex>
        </Flex>
    );
};
