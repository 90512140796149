import React from "react";
import Steps from "../../components/molecules/Steps";
import Features from "../../components/molecules/Features";
import { Hero } from "./components/Hero";
import { Logos } from "./components/Logos";
import { Statistics } from "./components/Statistics";
import { FAQ } from "./components/FAQ";
import { useSettings, useTheme } from "../../context/index";
import { VStack } from "@chakra-ui/react";
import {
    faDollarSign,
    faIdCard,
    faMobileAlt,
    faSearch
} from "@fortawesome/free-solid-svg-icons";

export const AboutTheClub = () => {
    const { name, metadata, id } = useTheme();
    const { isApp } = useSettings();

    const title = "Com o " + (metadata?.nomeLanding || name) + " você tem acesso:";

    const agreementId = id;

    const steps =
        agreementId === 251
            ? [
                  {
                      title: "Acesse",
                      description: (
                          <a href="https://universitariomais.com.br/assinante/">
                              www.universitariomais.com.br/assinante
                          </a>
                      ),
                      icon: faMobileAlt
                  },

                  {
                      title: "Insira seu CPF e senha",
                      description: "",
                      icon: faIdCard
                  },

                  {
                      title: 'Clique em "Confira os Descontos"',
                      description:
                          "O clube Partiu! possui centenas de opções de ofertas para você aproveitar.",
                      icon: faSearch
                  },

                  {
                      title: "Economize",
                      description:
                          "Agora é só economizar e usar quantas vezes quiser. Mas lembre-se de ficar atento às regras das empresas.",
                      icon: faDollarSign
                  }
              ]
            : [
                  {
                      title: "Baixe o App",
                      description:
                          `Baixe o app ${(metadata?.nomeLanding || name)}!, faça seu cadastro, escolha as melhores promoções e aproveite os descontos.`,
                      icon: faMobileAlt
                  },

                  {
                      title: "Procure uma Oferta",
                      description:
                          `O ${(metadata?.nomeLanding || name)}! possui centenas de opções de ofertas para você aproveitar.`,
                      icon: faSearch
                  },

                  {
                      title: "Faça Check-in",
                      description:
                          "Escolheu a promoção? Basta fazer o check-in no estabelecimento e pronto, já economizou.",
                      icon: faIdCard
                  },

                  {
                      title: "Economize",
                      description:
                          "Agora é só economizar e usar quantas vezes quiser. Mas lembre-se de ficar atento às regras dos estabelecimentos.",
                      icon: faDollarSign
                  }
              ];

    const features = [
        "Mais de 3000 estabelecimentos físicos e online!",
        "Um mundo de vantagens na palma da sua mão.",
        "Até 50% de desconto em diversos estabelecimentos.",
        "Economia nos melhores locais e sem complicação.",
        "Uso diário e ilimitado."
    ];

    return (
        <VStack width="100%">
            {!isApp ? (
                <>
                    <Hero />
                    <Logos />
                    <Statistics />
                </>
            ) : (
                <></>
            )}
            <VStack width="100%" backgroundColor="#f6f5fa">
                <Features title={title} small features={features} />
            </VStack>

            <Steps id="como-usar" title="Como Usar?" steps={steps} />
            <FAQ />
        </VStack>
    );
};
