import React, { useEffect, useState } from "react"
import { Heading, HStack, Text, VStack } from "@chakra-ui/layout"

import { Select, Box, Center, CircularProgress, Flex } from "@chakra-ui/react"

import { useTheme } from "../../../context/index"
import { useForm } from "react-hook-form"
import { FormInput } from "../../../components/atoms/FormInput"
import { Input } from "@chakra-ui/input"
import { Checkbox } from "@chakra-ui/react"
import { ErrorText } from "../../../components/atoms/ErrorText"
import { partiuClient } from "../../../axios"
import { Title } from "../../../components/atoms/Title"

import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api"
import { verificateCPF, verificateCnpj } from "../../../utils"

export const BePartnerForm = ({
    setTabIndex,
    company,
    store,
    discount,
    user,
    setCompany,
    setStore,
    setDiscount,
    setUser,
    setIsOpen,
    setCoverPhoto,
    setDiscountImage,
    setExclusive
}) => {

    const {
        register,
        watch,
        handleSubmit,
        formState: { errors }
    } = useForm({
        mode: 'onBlur'
    })

    const [tipoDeEstabelecimento, setTipoDeEstabelecimento] = useState("")

    const [changeCep, setChangeCep] = useState("")
    const [cepToLoad, setCepToLoad] = useState(null)
    const [cnpjToLoad, setCnpjToLoad] = useState(null)
    const { menuColor, menuFontColor, name, metadata, id } = useTheme()

    const [acceptedTerms, setAcceptedTerms] = useState(false)
    const [approveDiscount, setApproveDiscount] = useState(false)

    const [location, setLocation] = useState(null)
    const [cnpjData, setCnpjData] = useState({})

    const [discountType, setDiscountType] = useState("")

    const [error, setError] = useState("")

    const [map, setMap] = useState(null)

    const [delivery, setDelivery] = useState(false)
    const [promocao, setPromocao] = useState(false)
    const [cumulativa, setCumulativa] = useState(false)
    const [disponibilidade, setDisponibilidade] = useState(false)
    const [outros, setOutros] = useState(false)
    const [outrosText, setOutrosText] = useState("")

    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: "AIzaSyB5tXrkiapXmzmvkkW0-7MQvfx1LL9VScQ",
    })

    const onLoad = function callback(map) {
        setMap(map)
    }

    console.log(name, id)

    watch((data, { name }) => {

        if (name === "cnpj") {
            let cnpj = data.cnpj
                .replace(/_/g, "")
                .replace(/-/g, "")
                .replace(/\//g, "")
                .replace(/\./g, "")

            setCompany({ ...company, cnpj: cnpj })
            if (cnpj !== null && cnpj.length === 14 && cnpj !== cnpjToLoad) {
                setCnpjToLoad(cnpj)
            }
        } else if (name === "cep") {
            setStore({ ...store, postal_code: data.cep })
            if (data.cep !== null && data.cep.replace("-", "").length === 8) {
                setCepToLoad(data.cep.replace("-", ""))
            }
        } else if (name === "site") {
            setCompany({ ...company, site: data.site })
        } else if (name === "description") {
            setCompany({ ...company, description: data.description })
        } else if (name === "discount") {
            setDiscount({ ...discount, discount: data.discount })
        } else if (name === "title_discount") {
            setDiscount({ ...discount, name: data.title_discount })
        } else if (name === "link_desconto") {
            setDiscount({ ...discount, link: data.link_desconto })
        } else if (name === "cupom_desconto") {
            setDiscount({ ...discount, coupon: data.cupom_desconto })
        }
    })

    useEffect(() => {
        let text = ""

        if (promocao) {
            text += "<p>Promoção válida somente para essa unidade.</p>"
        }

        if (delivery) {
            text += "<p>Exceto Delivery.</p>"
        }

        if (cumulativa) {
            text += "<p>Não cumulativa com outras promoções.</p>"
        }

        if (disponibilidade) {
            text += "<p>Sujeito a disponibilidade do produto e lotação do estabelecimento.</p>"
        }

        if (outros) {
            text += `<p>${outrosText}</p>`
        }

        setDiscount({ ...discount, rules: text })
    }, [delivery, promocao, cumulativa, disponibilidade, outros, outrosText])

    useEffect(() => {
        setError("")

        if (!tipoDeEstabelecimento) return;

        setDiscount({ ...discount, mode: tipoDeEstabelecimento })
    }, [tipoDeEstabelecimento])

    useEffect(() => {
        setError("")

        if (!discountType) return;

        setDiscount({ ...discount, type: discountType })
    }, [discountType])

    function getErrorText() {
        const keys = Object.keys(errors)

        if (keys.length === 0) return <></>

        return <ErrorText>{errors[keys[0]].message}</ErrorText>
    }

    function verify(data) {
        const email = company.email
        const phone = company.cellphone
        const cnpj = company.cnpj

        setError("")

        const emailVerification =
            /^(([^<>()[\]\\.,:\s@"]+(\.[^<>()[\]\\.,:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

        if (!emailVerification.test(email?.toLowerCase())) {
            return setError("Email inválido")
        }

        if (phone?.indexOf("_") !== -1) {
            return setError("Telefone inválido")
        }

        if (!company.category || company.category === "") {
            return setError("Prencha uma categoria")
        }

        if (tipoDeEstabelecimento === "") {
            return setError("Prencha um tipo de estabelecimento")
        }


        if (discountType === "") {
            return setError("Prencha um tipo de desconto")
        }

        if (!verificateCPF(cnpj) && !verificateCnpj(cnpj)) {
            return setError("CNPJ ou CPF inválido")
        }

        setIsOpen(true)
    }

    useEffect(() => {
        if (changeCep.replace("-", "").length === 8 && changeCep !== cepToLoad) {
            setCepToLoad(changeCep.replace("-", ""))
        }
    }, [changeCep])


    useEffect(() => {
        if (cepToLoad === null) return

        partiuClient.api
            .getAddressFromCEP(cepToLoad)
            .then((data) => {
                setStore({
                    ...store,
                    street: data.logradouro,
                    neighborhood: data.bairro,
                    state: data.uf,
                    city: data.localidade,
                    country: "Brasil"
                })

                let geocoder = new window.google.maps.Geocoder()

                geocoder.geocode(
                    {
                        address: `${store.number}, 
                        ${store.street}, 
                        ${store.city}, 
                        ${store.state}, 
                        ${cepToLoad}, 
                        ${'Brasil'}`,
                    },
                    function (results, status) {
                        if (status === "OK") {
                            map.setCenter(results[0].geometry.location)
                            setLocation(results[0].geometry.location)
                        }
                    }
                )
            })
            .catch(() => { })
    }, [cepToLoad, setStore, map])

    useEffect(() => {
        if (cnpjToLoad === null || cnpjToLoad.length < 14) return
        try {
            if (verificateCnpj(cnpjToLoad)) {
                partiuClient.api.getCompanyDataFromCNPJ(cnpjToLoad).then((data) => {
                    setCnpjData({
                        ...cnpjData,
                        nome_fantasia: data.fantasia,
                        //cep: data.cep.replace(".", "").replace("-", ""),
                        owner: data?.qsa[0]?.nome
                    })

                    setUser({
                        ...user,
                        full_name: data?.qsa[0]?.nome,
                        email: data.email
                    })

                    setCompany({
                        ...company,
                        corporate_name: data.nome,
                        name: data.fantasia,
                        owner: data?.qsa[0]?.nome,
                        email: data.email,
                        cellphone: data.telefone,
                    })

                    setStore({
                        ...store,
                        postal_code: data.cep.replace(".", "").replace("-", ""),
                        street: data.logradouro,
                        number: data.numero,
                        neighborhood: data.bairro,
                        state: data.uf,
                        city: data.municipio,
                        country: "Brasil"
                    })
                })
            } else {

            }
        } catch (e) { console.log(e) }
    }, [cnpjToLoad])

    return (
        <VStack spacing={0} width="100%" alignItems="flex-start">
            <form
                key="register-form"
                style={{ width: "100%", marginTop: 30, zIndex: 1 }}
                onSubmit={handleSubmit(verify, verify)}
            >
                <VStack spacing={5} width="100%" alignItems="flex-start" backgroundColor={'#FFF'} padding={4} borderRadius={8}>
                    <Title textAlign="left" fontWeight="800" color="black" >
                        Vamos começar?
                    </Title>

                    <FormInput
                        required
                        hookForm={register("cnpj")}
                        id={"cnpj"}
                        containerProps={{ width: "100%" }}
                        label="CNPJ*"
                        placeholder="99.999.999/0001-99"
                        mask="99.999.999/9999-99"
                    />
                    <Flex flexDirection="column" style={{ width: "100%" }}>
                        <Heading textAlign="left" marginBottom="10px" fontSize="14px">
                            RAZÃO SOCIAL*
                        </Heading>
                        <Input
                            required
                            width="100%"
                            placeholder={"Digite a razão social"}
                            type={"text"}
                            maxLength={150}
                            value={company.corporate_name}
                            er
                            onChange={(e) => { setCompany({ ...company, corporate_name: e.target.value }) }}
                        />
                    </Flex>
                    <Flex flexDirection="column" style={{ width: "100%" }}>
                        <Heading textAlign="left" marginBottom="10px" fontSize="14px">
                            NOME FANTASIA*
                        </Heading>
                        <Input
                            required
                            width="100%"
                            placeholder={"Digite o nome fantasia"}
                            type={"text"}
                            maxLength={150}
                            value={company.name}
                            onChange={(e) => { setCompany({ ...company, name: e.target.value }) }}
                        />
                    </Flex>

                    <Heading textAlign="left" marginBottom="10px" fontSize="14px">
                        CATEGORIA*
                    </Heading>
                    <Select
                        onChange={(e) => setCompany({ ...company, category: e.target.value })}
                        name="Categoria"
                        backgroundColor="#FAFAFA">
                        <option value="Selecione">Selecione uma categoria</option>
                        <option value="Cinema">Cinema</option>
                        <option value="Vestuário">Vestuário</option>
                        <option value="Beleza">Beleza</option>
                        <option value="Turismo">Turismo</option>
                        <option value="Educação">Educação</option>
                        <option value="Para Casa">Para Casa</option>
                        <option value="Farmácia">Farmácia</option>
                        <option value="Alimentação">Alimentação</option>
                        <option value="Saúde">Saúde</option>
                        <option value="Serviços">Serviços</option>
                        <option value="Petshop">Petshop</option>
                        <option value="Para o meu negócio">Para o meu negócio</option>
                        <option value="Automotivo">Automotivo</option>
                        <option value="Delivery">Delivery</option>
                        <option value="Diversão">Diversão</option>
                        <option value="Outros">Outros</option>
                    </Select>
                    <Heading textAlign="left" marginBottom="10px" fontSize="14px">
                        TIPO DE ESTABELECIMENTO*
                    </Heading>
                    <Select
                        onChange={(e) => setTipoDeEstabelecimento(e.target.value)}
                        name="Tipo de Estabelecimetno"
                        backgroundColor="#FAFAFA"
                    >
                        <option value="Selecione">Selecione o tipo de estabelecimento</option>
                        <option value="local">
                            Loja Física
                        </option>
                        <option value="online">
                            E-commerce
                        </option>
                    </Select>
                    <Box onClick={() => setTabIndex(1)} width={'100%'}>
                        <FormInput
                            required
                            id={"description"}
                            hookForm={register("description")}
                            containerProps={{ width: "100%" }}
                            maxLength={150}
                            label="Descrição da Loja*"
                            placeholder="Descrição da Loja*"
                        />
                    </Box>
                    <Box onClick={() => setTabIndex(1)} width={'100%'}>
                        <FormInput
                            required={tipoDeEstabelecimento !== "local" ? true : false}
                            id={"site"}
                            hookForm={register("site")}
                            containerProps={{ width: "100%" }}
                            label="Site da Loja"
                            placeholder="Site da Loja"
                        />
                    </Box>
                </VStack>
                {tipoDeEstabelecimento === "local" &&
                    <VStack spacing={5} width="100%" alignItems="flex-start" marginTop={8} backgroundColor={'#FFF'} padding={4} borderRadius={8}>
                        <Title textAlign="left" fontWeight="800" color="black" paddingTop={4} paddingBottom={8}>
                            Endereço da loja
                        </Title>
                        <Flex flexDirection="column" style={{ width: "100%" }}>
                            <Heading textAlign="left" marginBottom="10px" fontSize="14px">
                                CEP*
                            </Heading>
                            <Input
                                required
                                width="100%"
                                placeholder={"Digite o cep"}
                                type={"text"}
                                value={store.postal_code}
                                onChange={(e) => {
                                    setChangeCep(e.target.value)
                                    setStore({ ...store, postal_code: e.target.value })
                                }}
                            />
                        </Flex>
                        <Flex flexDirection="column" style={{ width: "100%" }}>
                            <Heading textAlign="left" marginBottom="10px" fontSize="14px">
                                RUA*
                            </Heading>
                            <Input
                                width="100%"
                                placeholder={"Digite a rua"}
                                type={"text"}
                                required
                                value={store.street}
                                onChange={(e) => { setStore({ ...store, street: e.target.value }) }}
                            />
                        </Flex>
                        <Flex flexDirection="column" style={{ width: "100%" }}>
                            <Heading textAlign="left" marginBottom="10px" fontSize="14px">
                                NÚMERO*
                            </Heading>
                            <Input
                                width="100%"
                                placeholder={"Digite o numero"}
                                type={"number"}
                                required
                                value={store.number}
                                onChange={(e) => { setStore({ ...store, number: e.target.value }) }}
                            />
                        </Flex>
                        <Flex flexDirection="column" style={{ width: "100%" }}>
                            <Heading textAlign="left" marginBottom="10px" fontSize="14px">
                                COMPLEMENTO
                            </Heading>
                            <Input
                                width="100%"
                                placeholder={"Digite o complemento"}
                                type={"text"}
                                value={store.complement}
                            //onChange={(e) => { setStore({ ...store, complement: e.target.value }) }}
                            />
                        </Flex>
                        <Flex flexDirection="column" style={{ width: "100%" }}>
                            <Heading textAlign="left" marginBottom="10px" fontSize="14px">
                                BAIRRO*
                            </Heading>
                            <Input
                                width="100%"
                                placeholder={"Digite o bairro"}
                                type={"text"}
                                required
                                value={store.neighborhood}
                                onChange={(e) => { setStore({ ...store, neighborhood: e.target.value }) }}
                            />
                        </Flex>
                        <FormInput
                            required
                            id={"cidade"}
                            hookForm={register("cidade")}
                            value={store.city}
                            onChange={(e) => setStore({ ...store, city: e.target.value })}
                            containerProps={{ width: "100%" }}
                            label="Cidade*"
                            placeholder="Cidade*"
                        />
                        <FormInput
                            required
                            id={"estado"}
                            hookForm={register("state")}
                            value={store.state}
                            onChange={(e) => setStore({ ...store, state: e.target.value })}
                            containerProps={{ width: "100%" }}
                            label="Estado*"
                            placeholder="Estado*"
                        />

                        {isLoaded ? (
                            <GoogleMap
                                mapContainerStyle={{
                                    width: "100%",
                                    height: "300px",
                                }}
                                center={location}
                                zoom={17}
                                onLoad={onLoad}
                            >
                                <Marker
                                    position={location}
                                    draggable={true}
                                    onDragEnd={(e) => setLocation(e.latLng)}
                                />
                            </GoogleMap>
                        ) : (
                            <Center>
                                <CircularProgress isIndeterminate />
                            </Center>
                        )}
                    </VStack>}
                <VStack spacing={5} width="100%" alignItems="flex-start" marginTop={8} backgroundColor={'#FFF'} padding={4} borderRadius={8}>
                    <Title textAlign="left" fontWeight="800" color="black" paddingTop={4} paddingBottom={8}>
                        Dados para contato
                    </Title>


                    <Flex flexDirection="column" style={{ width: "100%" }}>
                        <Heading textAlign="left" marginBottom="10px" fontSize="14px">
                            NOME DO DONO*
                        </Heading>
                        <Input
                            width="100%"
                            placeholder={"Nome Completo do Responsável da Loja"}
                            type={"text"}
                            required
                            value={company.owner}
                            onChange={(e) => {
                                setUser({ ...user, full_name: e.target.value })
                                setCompany({ ...company, owner: e.target.value })
                            }}
                        />
                    </Flex>
                    <Flex flexDirection="column" style={{ width: "100%" }}>
                        <Heading textAlign="left" marginBottom="10px" fontSize="14px">
                            EMAIL DO DONO*
                        </Heading>
                        <Input
                            width="100%"
                            placeholder={"Email para comunicação com a Loja"}
                            type={"text"}
                            required
                            value={company.email}
                            onChange={(e) => {
                                setUser({ ...user, email: e.target.value })
                                setCompany({ ...company, email: e.target.value })
                            }}
                        />
                    </Flex>
                    <Flex flexDirection="column" style={{ width: "100%" }}>
                        <Heading textAlign="left" marginBottom="10px" fontSize="14px">
                            TELEFONE DO DONO*
                        </Heading>
                        <Input
                            width="100%"
                            placeholder={"Celular para comunicação com o Responsável da Loja"}
                            type={"text"}
                            required
                            value={company.cellphone}
                            maxLength={50}
                            onChange={(e) => { setCompany({ ...company, cellphone: e.target.value }) }}
                        />
                    </Flex>
                </VStack>
                <VStack spacing={5} width="100%" alignItems="flex-start" marginTop={8} backgroundColor={'#FFF'} padding={4} borderRadius={8}>

                    <Title textAlign="left" fontWeight="800" color="black" paddingTop={4} paddingBottom={8}>
                        Dados de Desconto
                    </Title>
                    <Heading textAlign="left" marginBottom="10px" fontSize="14px">
                        TIPO DE DESCONTO*
                    </Heading>
                    <Select
                        onChange={(e) => {
                            setDiscountType(e.target.value)
                        }}
                        name="Assunto"
                        backgroundColor="#FAFAFA"
                    >
                        <option value="Selecione">Selecione o tipo do desconto</option>
                        <option value="discount">
                            Porcentagem
                        </option>
                        <option value="buy_and_gain">
                            Compre e Ganhe
                        </option>
                        <option value="treat">
                            Mimo
                        </option>
                    </Select>
                    {discountType === "discount" &&
                        <FormInput
                            required
                            onClick={() => setTabIndex(0)}
                            hookForm={register("discount")}
                            type="number"
                            containerProps={{ width: "100%" }}
                            id={"discount"}
                            maxLength={4}
                            label="Valor do Desconto*"
                            placeholder="Porcentagem de desconto"
                        />}
                    <FormInput
                        required
                        onClick={() => setTabIndex(0)}
                        hookForm={register("title_discount")}
                        containerProps={{ width: "100%" }}
                        id={"title_discount"}
                        label="Título do Desconto*"
                        placeholder="Título do desconto (como aparecerá na listagem do app)"
                    />
                    <Heading textAlign="left" marginBottom="10px" fontSize="14px">
                        REGULAMENTO
                    </Heading>
                    <HStack onClick={() => setTabIndex(2)} width={'100%'}>
                        <Checkbox
                            onChange={(e) =>
                                setCumulativa(e.target.checked)
                            }
                        />
                        <Text>
                            Não cumulativa com outras promoções.
                        </Text>
                    </HStack>
                    <HStack onClick={() => setTabIndex(2)} width={'100%'}>
                        <Checkbox
                            onChange={(e) =>
                                setDelivery(e.target.checked)
                            }
                        />
                        <Text>
                            Exceto Delivery.
                        </Text>
                    </HStack>
                    <HStack onClick={() => setTabIndex(2)} width={'100%'}>
                        <Checkbox
                            onChange={(e) =>
                                setPromocao(e.target.checked)
                            }
                        />
                        <Text>
                            Promoção válida somente para essa unidade.
                        </Text>
                    </HStack>
                    <HStack onClick={() => setTabIndex(2)} width={'100%'}>
                        <Checkbox
                            onChange={(e) =>
                                setDisponibilidade(e.target.checked)
                            }
                        />
                        <Text>
                            Sujeito a disponibilidade do produto e lotação do estabelecimento.
                        </Text>
                    </HStack>
                    <HStack onClick={() => setTabIndex(2)} width={'100%'}>
                        <Checkbox
                            onChange={(e) =>
                                setOutros(e.target.checked)
                            }
                        />
                        <Text>
                            Outros
                        </Text>
                    </HStack>
                    {outros &&
                        <Flex flexDirection="column" style={{ width: "100%" }}>
                            <Heading textAlign="left" marginBottom="10px" fontSize="14px">
                                Outros
                            </Heading>
                            <Input
                                width="100%"
                                placeholder={"Digite o regulamento complementar..."}
                                type={"text"}
                                value={outrosText}
                                onChange={(e) => setOutrosText(e.target.value)}
                            />
                        </Flex>}
                    {tipoDeEstabelecimento === "online" && <FormInput
                        required
                        hookForm={register("link_desconto")}
                        containerProps={{ width: "100%", paddingBottom: 3 }}
                        label="Link de desconto*"
                        placeholder="Digite o regulamento complementar..."
                    />}
                    {tipoDeEstabelecimento === "online" && <FormInput
                        required
                        hookForm={register("cupom_desconto")}
                        containerProps={{ width: "100%", paddingBottom: 3 }}
                        label="Cupom de desconto*"
                        maxLength={30}
                        placeholder="Digite o regulamento complementar..."
                    />}
                </VStack>
                <VStack spacing={5} width="100%" alignItems="flex-start" marginTop={8} backgroundColor={'#FFF'} padding={4} borderRadius={8}>
                    <Title textAlign="left" fontWeight="800" color="black" paddingTop={4} paddingBottom={4}>
                        Imagens
                    </Title>
                    <Heading textAlign="left" marginBottom="10px" fontSize="14px">
                        Capa* (1080px x 764px)
                    </Heading>
                    <input type="file" accept="image/*"
                        onChange={async (e) => {
                            const file = e.target.files[0]
                            setCompany({ ...company, cover_photo: file })
                            const reader = new FileReader();
                            reader.onloadend = function () {
                                setCoverPhoto(reader.result)
                            }
                            if (file) {
                                reader.readAsDataURL(file);
                            }

                        }}></input>
                    <Heading textAlign="left" marginBottom="10px" fontSize="14px">
                        Promoção* (150px x 150px)
                    </Heading>
                    <input type="file" accept="image/*"
                        onChange={async (e) => {
                            const file = e.target.files[0]
                            setDiscount({ ...discount, image: file })

                            const reader = new FileReader();
                            reader.onloadend = function () {
                                setDiscountImage(reader.result)
                            }
                            if (file) {
                                reader.readAsDataURL(file);
                            }
                        }}></input>

                    <Heading textAlign="left" marginBottom="10px" fontSize="14px">
                        Logo* (250px x 160px)
                    </Heading>
                    <input type="file" accept="image/*"
                        onChange={async (e) => {
                            const file = e.target.files[0]

                            setCompany({ ...company, logo: file })
                        }}></input>
                </VStack>
                <VStack spacing={5} width="100%" alignItems="flex-start" marginTop={8} backgroundColor={'#FFF'} padding={4} borderRadius={8}>
                    <Title textAlign="left" fontWeight="800" color="black" paddingTop={4} paddingBottom={4}>
                        Aceites
                    </Title>
                    <HStack>
                        <Checkbox
                            onChange={(e) =>
                                setAcceptedTerms(e.target.checked)
                            }
                        />
                        <Text>
                            Estou de acordo com os{" "}
                            <a target="_blank" href="/legal/be-partner/termos-de-uso">
                                Termos de Uso
                            </a>
                        </Text>
                    </HStack>
                    <HStack>
                        <Checkbox
                            onChange={(e) =>
                                setExclusive(e.target.checked)
                            }
                        />
                        <Text>
                            Quero ser exclusivo Clube {(metadata?.nomeLanding || name)}
                        </Text>
                    </HStack>
                    <HStack>
                        <Checkbox
                            onChange={(e) =>
                                setApproveDiscount(e.target.checked)
                            }
                        />
                        <Text>
                            Entendo que o desconto só será disponibilizado quando aprovado pela {(metadata?.nomeLanding || name)}!
                        </Text>
                    </HStack>
                    {error !== "" &&
                        <Text fontWeight="bold" color={'#F00'}>
                            Não é possível prosseguir: {error}
                        </Text>}
                    <Input
                        backgroundColor={menuColor}
                        color={menuFontColor}
                        disabled={!acceptedTerms || !approveDiscount || !company.cover_photo || !discount.image}
                        fontWeight="bold"
                        type="submit"
                        value="Continuar"
                    />
                </VStack>
            </form>
        </VStack>
    )
}