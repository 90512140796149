import { Route, Navigate, Routes, useLocation } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { AboutTheClub } from "../pages/AboutTheClub";
import Contact from "../pages/Contact";
import Legal from "../pages/Legal/Legal";
import React from "react";
import { SearchPage } from "../pages/Search";
import { PromoDetails } from "../pages/PromoDetails/index";
import { BePartner } from "../pages/BePartner";
import { HomePage } from "../pages/Home";
import { BottomMenu } from "../components/organisms/BottomMenu";
import { ProfilePage } from "../pages/Profile";
import { IOSSpacer } from "../components/atoms/IOSSpacer";
import { EconometerPage } from "../pages/Econometer";
import { Footer } from "../components/organisms/Footer";
import DesktopMenu from "../components/organisms/DesktopMenu";
import { useProfile } from "../context/user";
import { HowToUse } from "../pages/HowToUse";
import { useTheme } from "../context";
import { Documentation } from "../documentation";
import { NeoLogin } from "../pages/Integrations/NeoLogin";
import { PlayhubLogin } from "../pages/Integrations/PlayhubLogin";
import { NotInAgreement } from "../pages/NotInAgreement";
import Privacy from "../pages/Legal/Privacy";
import Terms from "../pages/Legal/Terms";
import { YoucastLogin } from "../pages/Integrations/YoucastLogin";
import { PlayhubWhitelabel } from "../pages/Integrations/PlayhubWhitelabel";
import { IsuperLogin } from "../pages/Integrations/IsuperLogin";
import { VoalleLogin } from "../pages/Integrations/VoalleLogin";
import { BuyStatus } from "../pages/BuySuccess";
import TermsBePartner from "../pages/Legal/TermsBePartner";
import { MaxxLogin } from "../pages/Integrations/MaxxLogin";
import { BooksPage } from "../pages/Books/BooksPage";
import PlansPage from "../pages/Plans";
import { PartnerForm } from "../pages/PartnerForm";
import { DrmEbook } from "../pages/DrmEbook";
import { LesteLogin } from "../pages/Integrations/LesteLogin";
import LibraryPage from "../pages/Library";
import {MyPucharsesPage} from "../pages/MyPurchases/MyPucharsesPage";
import MyBooksPage from "../pages/MyBooks";
import { AuthPage } from "../pages/Auth";
import { SercomtelLogin } from "../pages/Integrations/SercomtelLogin";
import { Register } from "../pages/Login/components/Register";
import { LoginOptionsPage } from "../pages/Login/components/RegistrationMode";
import { RegisterWithPromocode } from "../pages/Login/components/RegistrationWithPromocode";
import { RegistrationWithDocument } from "../pages/Login/components/RegistrationWithDocument";
import { ConfirmAgreement } from "../pages/Login/components/ConfirmAgreement";
import { DrmAudioBook } from "../pages/DrmAudioBook";
import { SubscriptionStatus } from "../pages/SubscriptionStatus";
import { SubscriptionMobileStatus } from "../pages/SubscriptionMobileStatus";
import { NoSubscriptionModal } from "../components/molecules/NoSubscriptionModal";
import SuccessPage from "../pages/Success";
import { SSOCallback } from "../pages/Sso";
import MyMagazines from "../pages/MyMagazines";
import { MedlarLogin } from "../pages/Integrations/Medlar";
import { PromotionPurchase } from "../pages/PromoDetails/components/PromotionPurchase";
import { DirectLogin } from "../pages/Integrations/DirectLogin";
import { SurfLogin } from "../pages/Integrations/Surf";
import { AdapterLogin } from "../pages/Integrations/Adapter";
import { MKLogin } from "../pages/Integrations/MKLogin";

function ProtectedPage({ children }) {
    const profile = useProfile();

    const { integratedLogin } = useTheme()

    if (!profile.logged) {
        return <Navigate to={{ pathname: integratedLogin ? "/login/parceiro" : "/login" }} />;
    }

    return children;
}

export function PartiuRouter() {
    const location = useLocation();
    const { integratedLogin, idp, bookstoreModule } = useTheme();

    if (window.location.hostname.split(".")[0] === "developer") {
        return <Documentation />;
    }

    const IntegratedLogin = () => {
        if (integratedLogin === "neo") return <NeoLogin />
        else if (integratedLogin === "youcast") return <YoucastLogin />
        else if (integratedLogin === "isuper") return <IsuperLogin />
        else if (integratedLogin === "voalle") return <VoalleLogin />
        else if (integratedLogin === "maxx") return <MaxxLogin />
        else if (integratedLogin === "leste") return <LesteLogin />
        else if (integratedLogin === "sercomtel") return <SercomtelLogin />
        else if (integratedLogin === "adapter") return <AdapterLogin />
        else if (integratedLogin === "mk") return <MKLogin />
        else if (integratedLogin === "medlar") return <MedlarLogin />
        else if (integratedLogin === "surf") return <SurfLogin />
        else if (integratedLogin === "direct") return <DirectLogin />
        else {
            if (idp) {
                return <PlayhubWhitelabel />
            } else {
                return <PlayhubLogin />
            }
        }
    }

    return (
        <React.Fragment>
            <IOSSpacer />
            {!window.location.pathname.includes("ebook") && <DesktopMenu />}
            <TransitionGroup component={null}>
                <CSSTransition
                    key={location.pathname}
                    classNames="fade"
                    timeout={300}
                >
                    <Routes location={location}>
                        <Route
                            path="/home"
                            exact
                            element={<Navigate to="/" />}
                        />
                        <Route path="/perfil" exact element={<ProfilePage />} />
                        <Route path="/login" exact element={<LoginOptionsPage />} />
                        <Route path="/login/promocode" exact element={<RegisterWithPromocode />} />
                        <Route path="/login/document" exact element={<RegistrationWithDocument />} />
                        <Route path="/login/validate-success" exact element={<ConfirmAgreement />} />
                        <Route path="/signup" exact element={<Register />} />
                        <Route path="/auth" exact element={<AuthPage />} />
                        <Route path="/login/auth" exact element={<AuthPage />} />
                        <Route path="/login/oauth2/brisa/callback" exact element={<SSOCallback />} />
                        <Route path="/" exact element={<HomePage />} />
                        <Route path="/busca" exact element={<SearchPage />} />
                        <Route path="/rede" exact element={<SearchPage />} />
                        <Route
                            path="/sobre-o-clube"
                            element={<AboutTheClub />}
                        />
                        <Route path="/cadastrar-loja" element={<BePartner />} />
                        <Route path="/formulario-cadastro" element={<PartnerForm />} />
                        <Route path="/contato" element={<Contact />} />
                        <Route
                            path="/econometro"
                            element={
                                <ProtectedPage>
                                    <EconometerPage />
                                </ProtectedPage>
                            }
                        />

                        <Route path="/como-usar" element={<HowToUse />} />
                        <Route path="/combos" element={<PlansPage />} />
                        <Route path="/sucesso" element={<SuccessPage />} />
                        <Route path="/subscription-status/:id" element={<SubscriptionStatus />} />
                        <Route path="/purchase-subscription-mobile/:token" element={<SubscriptionMobileStatus />} />
                        <Route path="/minhas-compras" element={<MyPucharsesPage />} />
                        <Route path="/meus-livros" element={<LibraryPage />} />
                        <Route path="/minhas-revistas" element={<LibraryPage />} />
                        <Route path="/livros-recebidos" element={<MyBooksPage />} />
                        <Route path="/revistas-recebidas" element={<MyBooksPage />} />
                        <Route path="/revistas-digitais" element={<MyMagazines />} />
                        <Route
                            path="/rede"
                            exact
                            render={<Navigate to={{ pathname: "/" }} />}
                        />
                        <Route path="/legal" exact element={<Legal />} />
                        <Route path="/legal/politica-de-privacidade" exact element={<Privacy />} />
                        <Route path="/privacy-policy" exact element={<Privacy />} />
                        <Route path="/legal/termos-de-uso" exact element={<Terms />} />
                        <Route path="/legal/be-partner/termos-de-uso" exact element={<TermsBePartner />} />
                        <Route
                            path="/detalhes/:mode/:id"
                            element={<PromoDetails />}
                        />
                        <Route
                            path="/detalhes/:mode/:id/compra"
                            element={<PromotionPurchase />}
                        />
                        <Route
                            path="/status-compra/:id"
                            element={<BuyStatus />}
                        />
                        <Route
                            path="/sem-vinculo"
                            element={<NotInAgreement />}
                        />
                        <Route
                            path="/login/parceiro"
                            exact
                            element={<IntegratedLogin />}
                        />
                        <Route
                            path="/drm/ebook"
                            exact
                            element={<DrmEbook />}
                        />
                        <Route
                            path="/drm/audiobook"
                            exact
                            element={<DrmAudioBook />}
                        />
                        {bookstoreModule && <Route path="/biblioteca" exact element={<BooksPage />} />}
                        {bookstoreModule && <Route path="/banca" exact element={<BooksPage />} />}
                    </Routes>
                </CSSTransition>
            </TransitionGroup>
            <NoSubscriptionModal />                    
            <BottomMenu />
            {!window.location.pathname.includes("ebook") && <Footer />}
        </React.Fragment>
    );
}
