import { Box, VStack, Image, Text } from "@chakra-ui/react";
import { Carousel } from "react-bootstrap";

export function Label(props) {
    return (
        <Text
            color="#000000"
            fontFamily={"Glory"}
            fontWeight={"400"}
            fontSize={14}
            {...props}
        />
    );
}

const cinemark = [
    {
        desc: '1. Clicar em "usar desconto":',
        image: "/assets/cinemas/cinemark/step1.png"
    },
    {
        desc: '2. Assinalar o "de Acordo":',
        image: "/assets/cinemas/cinemark/step2.png"
    },
    {
        desc: "3. Efetue o pagamento com seu cartão de crédito:",
        image: "/assets/cinemas/cinemark/step3.png"
    },
    {
        desc: '4. Entre em cinemark.com.br, escolha a unidade, o filme desejado, o assento e clique em "escolher tipo de ingresso":',
        image: "/assets/cinemas/cinemark/step4.png"
    },
    {
        desc: '5. Na página para escolher seus ingressos, clique em "voucher", insira o(s) código(s), de 9 digitos, recebido(s) por email e ele será trocado pelo seu ingresso:. Clicar em "usar desconto":',
        image: "/assets/cinemas/cinemark/step5.png"
    },
    {
        desc: '6. Abra o app Cinemark, clique em "pedidos" e seu ingresso estará lá:',
        image: "/assets/cinemas/cinemark/step6.png"
    }
];

const cinepolis = [
    {
        desc: '1. Clicar em "usar desconto":',
        image: "/assets/cinemas/cinepolis/step1.png"
    },
    {
        desc: '2. Assinalar o "de Acordo":',
        image: "/assets/cinemas/cinepolis/step2.png"
    },
    {
        desc: "3. Efetue o pagamento com seu cartão de crédito:",
        image: "/assets/cinemas/cinepolis/step3.png"
    },
    {
        desc: "4. O(s) código(s) recebido(s), de 16 digitos, por email deve ser trocado(s) pelo(s) ingresso(s) apenas nas bilheterias dos cinemas Cinépolis."
        //image: '/assets/cinemas/cinepolis/step4.png',
    }
];

const cinesystem = [
    {
        desc: '1. Clicar em "usar desconto":',
        image: "/assets/cinemas/cinesystem/step1.png"
    },
    {
        desc: '2. Assinalar o "de Acordo":',
        image: "/assets/cinemas/cinesystem/step2.png"
    },
    {
        desc: "3. Efetue o pagamento com seu cartão de crédito:",
        image: "/assets/cinemas/cinesystem/step3.png"
    },
    {
        desc: '4. Entre em cinesystem.com.br, escolha a unidade, o filme desejado, o assento e clique em "escolher tipo de ingressos":.',
        image: "/assets/cinemas/cinesystem/step4.png"
    },
    {
        desc: '5. Na página para escolher seus ingressos, clique em "cinesystem pass", insira o(s) código(s), de 9 digitos, recebido(s) por email e ele será trocado pelo seu ingresso:',
        image: "/assets/cinemas/cinesystem/step5.png"
    }
];

const KINOPLEX = [
    {
        desc: '1. Clicar em "usar desconto":',
        image: "/assets/cinemas/kinoplex/step1.png"
    },
    {
        desc: '2. Assinalar o "de Acordo":',
        image: "/assets/cinemas/kinoplex/step2.png"
    },
    {
        desc: "3. Efetue o pagamento com seu cartão de crédito:",
        image: "/assets/cinemas/kinoplex/step3.png"
    },
    {
        desc: '4. Entre em kinoplex.com.br, escolha a unidade, o filme desejado, o assento e clique em "escolher tipo de ingressos"',
        image: "/assets/cinemas/kinoplex/step4.png"
    },
    {
        desc: '5 Na página para escolher seus ingressos, clique em "cineticket", insira o(s) código(s), de 9 digitos, recebido(s) por email e ele será trocado pelo seu ingresso:',
        image: "/assets/cinemas/kinoplex/step5.png"
    }
];

const uci = [
    {
        desc: '1. Clicar em "usar desconto":',
        image: "/assets/cinemas/uci/step1.png"
    },
    {
        desc: '2. Assinalar o "de Acordo":',
        image: "/assets/cinemas/uci/step2.png"
    },
    {
        desc: "3. Efetue o pagamento com seu cartão de crédito:",
        image: "/assets/cinemas/uci/step3.png"
    },
    {
        desc: "4.O(s) código(s) recebido(s), de 22 digitos, por email deve ser trocado pelo(s) ingresso(s) apenas nas bilheterias dos cinemas UCI."
        //image: '/assets/cinemas/uci/step4.png',
    }
];
export function HowToUse({ name }) {
    if (
        name &&
        [
            "cinemark",
            "cinépolis",
            "cinesystem",
            "kinoplex",
            "uci cinemas"
        ].includes(name.toLocaleLowerCase())
    ) {
        return (
            <Box width="100%">
                <VStack align={"center"} spacing={4} width={"100%"}>
                    <Text
                        fontSize={20}
                        fontFamily={"Glory"}
                        fontWeight={"bold"}
                        textAlign={"center"}
                    >
                        Como usar seu ingresso
                    </Text>
                    <VStack
                        width={"100%"}
                        align={"center"}
                        justifyContent={"center"}
                        spacing={4}
                    >
                        {name?.toLowerCase() === "CINEMARK".toLowerCase() && (
                            <Carousel
                                style={{
                                    width: "100%"
                                }}
                                autoFocus={false}
                                indicators={false}
                            >
                                {cinemark.map((step, index) => (
                                    <Carousel.Item
                                        width={"100%"}
                                        key={index}
                                        align={"center"}
                                        spacing={4}
                                    >
                                        <Text
                                            fontSize={16}
                                            marginBottom="20px"
                                            fontFamily={"Glory"}
                                            fontWeight={"500"}
                                            textAlign={"center"}
                                        >
                                            {step.desc}
                                        </Text>
                                        {step.image && (
                                            <Box width={"100%"}>
                                                <Image
                                                    maxHeight={500}
                                                    src={step.image}
                                                    alt={step.desc}
                                                />
                                            </Box>
                                        )}
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                        )}
                        {name?.toLowerCase() === "Cinépolis".toLowerCase() && (
                            <Carousel
                                style={{
                                    width: "100%"
                                }}
                                indicators={false}
                            >
                                {cinepolis.map((step, index) => (
                                    <Carousel.Item
                                        width={"100%"}
                                        key={index}
                                        align={"center"}
                                        spacing={4}
                                    >
                                        <Text
                                            fontSize={16}
                                            marginBottom="20px"
                                            fontFamily={"Glory"}
                                            fontWeight={"500"}
                                            textAlign={"center"}
                                        >
                                            {step.desc}
                                        </Text>
                                        {step.image && (
                                            <Box width={"100%"}>
                                                <Image
                                                    maxHeight={500}
                                                    src={step.image}
                                                    alt={step.desc}
                                                />
                                            </Box>
                                        )}
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                        )}
                        {name?.toLowerCase() === "CINESYSTEM".toLowerCase() && (
                            <Carousel
                                style={{
                                    width: "100%"
                                }}
                                indicators={false}
                            >
                                {cinesystem.map((step, index) => (
                                    <Carousel.Item
                                        width={"100%"}
                                        key={index}
                                        align={"center"}
                                        spacing={4}
                                    >
                                        <Text
                                            fontSize={16}
                                            marginBottom="20px"
                                            fontFamily={"Glory"}
                                            fontWeight={"500"}
                                            textAlign={"center"}
                                        >
                                            {step.desc}
                                        </Text>
                                        {step.image && (
                                            <Box width={"100%"}>
                                                <Image
                                                    maxHeight={500}
                                                    src={step.image}
                                                    alt={step.desc}
                                                />
                                            </Box>
                                        )}
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                        )}
                        {name?.toLowerCase() === "KINOPLEX".toLowerCase() && (
                            <Carousel
                                style={{
                                    width: "100%"
                                }}
                                indicators={false}
                            >
                                {KINOPLEX.map((step, index) => (
                                    <Carousel.Item
                                        width={"100%"}
                                        key={index}
                                        align={"center"}
                                        spacing={4}
                                    >
                                        <Text
                                            fontSize={16}
                                            marginBottom="20px"
                                            fontFamily={"Glory"}
                                            fontWeight={"500"}
                                            textAlign={"center"}
                                        >
                                            {step.desc}
                                        </Text>
                                        {step.image && (
                                            <Box width={"100%"}>
                                                <Image
                                                    maxHeight={500}
                                                    src={step.image}
                                                    alt={step.desc}
                                                />
                                            </Box>
                                        )}
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                        )}
                        {name?.toLowerCase() ===
                            "UCI Cinemas".toLowerCase() && (
                            <Carousel
                                style={{
                                    width: "100%"
                                }}
                                indicators={false}
                            >
                                {uci.map((step, index) => (
                                    <Carousel.Item
                                        width={"100%"}
                                        key={index}
                                        align={"center"}
                                        spacing={4}
                                    >
                                        <Text
                                            maxW={"80%"}
                                            fontSize={20}
                                            fontFamily={"Glory"}
                                            fontWeight={"500"}
                                            textAlign={"center"}
                                        >
                                            {step.desc}
                                        </Text>
                                        {step.image && (
                                            <Box width={"100%"}>
                                                <Image
                                                    maxHeight={500}
                                                    src={step.image}
                                                    alt={step.desc}
                                                />
                                            </Box>
                                        )}
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                        )}
                    </VStack>
                </VStack>
            </Box>
        );
    }
    return null;
}
